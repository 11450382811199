import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AppServices from '../../services/AppService';

const inititalState = {
    listAppLoading: false,
    listAppByKeyword: null,
    listAppError: null,
    currency: 'USD',
};

export const asyncGetListApp = createAsyncThunk(
    'app/getListApp',
    async ({ keys = [
        'edu_login_detail',
        'edu_login_bg_mobile',
        'edu_login_bg_desktop',
        'profile-completion',
        'verification_exclude_list',
    ], startWith = null }) => {
        const listApp = await AppServices.getListApp({ keys, startWith });
        return { listApp, startWith };
    },
);

export const appSlice = createSlice({
    name: 'app',
    initialState: inititalState,
    reducers: {
        resetListApp: () => ({ ...inititalState }),
        setCurrency: (state, { payload }) => {
            state.currency = payload;
        },
        removeListAppByKeyword: (state, { payload }) => {
            Object.keys(state.listAppByKeyword).forEach((key) => {
                if (key.includes(payload)) {
                    delete state.listAppByKeyword[key];
                }
            });
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(asyncGetListApp.pending, (state) => {
            state.listAppLoading = true;
        });
        addCase(asyncGetListApp.fulfilled, (state, { payload }) => {
            if (typeof payload.listApp === 'object') {
                const allowedKeys = [
                    // MANDATORY CONFIG
                    'edu_login_detail',
                    'edu_login_bg_mobile',
                    'edu_login_bg_desktop',
                    'profile-completion',
                    'verification_exclude_list',
                    'grace_period_monthly',
                    'promo_popup',
                    'promo_banner',
                     'ads_object_preview',
                ];
                const newKeywords = payload.listApp?.reduce((acc, app) => {
                    const { key, ...rest } = app;
                    if (allowedKeys.some((allowedKey) => key.includes(allowedKey))) {
                        acc[key] = rest;
                    }
                    return acc;
                }, {});

                const oldKeywordsWithoutPromo = Object.keys(state.listAppByKeyword || {})
                .filter(
                    (key) => !key.includes('promo_banner') && !key.includes('promo_popup'),
                )
                .filter((key) => (payload.startWith ? !key.includes(payload.startWith) : true))
                .reduce((acc, key) => {
                    acc[key] = state.listAppByKeyword[key];
                    return acc;
                }, {});

                state.listAppByKeyword = {
                    ...oldKeywordsWithoutPromo,
                    ...newKeywords,
                };
            }
            state.listAppLoading = false;
        });
        addCase(asyncGetListApp.rejected, (state, { payload }) => {
            state.listAppError = payload;
            state.listAppLoading = false;
        });
    },
});

export const { resetListApp, setCurrency, removeListAppByKeyword } = appSlice.actions;

export const appState = (state) => state.app;

const appReducer = appSlice.reducer;

export default appReducer;
