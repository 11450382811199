import { createSlice } from '@reduxjs/toolkit';
import nookies from 'nookies';

const initialState = {
  user: null,
  token: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
      const token = action.payload?.stsTokenManager?.accessToken
      || action.payload?.accessToken
      || null;
    nookies.set(undefined, 'token', token, {
      maxAge: 30 * 24 * 60 * 60,
      path: '/',
      domain: 'assemblrworld.com',
    });
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    logout(state) {
      state.user = null;
      state.token = null;
    },
  },
});

export const { setUser, setToken, logout } = authSlice.actions;

export const authState = (state) => state.auth;

const authReducer = authSlice.reducer;

export default authReducer;
